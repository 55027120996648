import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/uchur',
	name:'index',
    redirect: '',
	meta:{
	    title:'خىزمەت ئۇچۇرلىرى'
	},
	component: resolve => require(['@/views/index/index.vue'], resolve),
  },{
    path: '/detail',
    name: 'detail',
    meta:{
        title:'详情'
    },
    component: resolve => require(['@/views/vip/index.vue'], resolve),
  },{
    path: '/404',
    name: '404',
    component: resolve => require(['@/views/error/404.vue'], resolve),
  },{
	  path:'/footer',
	  name:'footer'
  },{
    path: '/down',
    name: 'down',
    meta:{
        title:'下载'
    },
    component: resolve => require(['@/views/down/index.vue'], resolve),
  },{
    path: '/',
    name: 'free',
    meta:{
        title:'素材'
    },
    component: resolve => require(['@/views/free/index.vue'], resolve),
  },{
    path: '/show/:id',
    name: 'show',
    meta:{
        title:'详情'
    },
    component: resolve => require(['@/views/show/index.vue'], resolve),
  },{
    path: 'show/:id',
    name: 'show',
    meta:{
        title:'详情'
    },
    component: resolve => require(['@/views/show/index.vue'], resolve),
  },{
    path: '/:username',
    name: 'ucenter',
    meta:{
        title:'个人主页'
    },
    component: resolve => require(['@/views/ucenter/index.vue'], resolve),
  }
]

const createRouter = () => new VueRouter({
  scrollBehavior: () => ({
    y: 0
  }),
  routes: routes,
  mode:'history'
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router