

export default {
    state :{
        addRoutes:[],
        menu:[],
        actions:[],
        username:'',
        headimg:'',
        site_title:''
    },
    mutations :{
        setRoute(state,route){
            state.addRoutes = route
        },
        setMenu(state,menu){
            state.menu = menu
        },
        setActions(state,actions){
            state.actions = actions
        },
        setUserInfo(state,val){
            state.username = val.user
            state.headimg = val.headimg
            state.role_id = val.role_id
            state.site_title = val.site_title
        }
    },
    actions :{
        
    }
}