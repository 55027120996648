

const storageSetting = JSON.parse(localStorage.getItem('layout-setting')) || ''

export default {

    state :{
    },
    mutations :{
        
    },
    actions :{
        
    }
}